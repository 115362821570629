<template>
  <el-dialog
    :title="!dataForm.id ? '新增' : !disabled ? '修改' : '查看'"
    :close-on-click-modal="false"
    :visible.sync="visible"
    append-to-body
    class="missionPart"
  >
    <el-form
      :model="dataForm"
      :rules="dataRule"
      ref="dataForm"
      @keyup.enter.native="dataFormSubmit()"
      label-width="80px"
    >
      <div class="tablePart">
        <el-form-item label="分类名称" prop="name">
          <el-input
            v-model="dataForm.name"
            :disabled="disabled"
            placeholder="输入分类名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="是否显示" prop="name">
          <el-tooltip class="item" effect="dark" placement="top">
            <i class="el-icon-question"> </i>

            <div style="width: 200px" slot="content">
              是否在小程序的商品列表侧边栏上显示
            </div>
          </el-tooltip>
          <el-checkbox
            v-model="dataForm.isShow"
            :disabled="disabled"
            placeholder="是否显示"
            >是否显示</el-checkbox
          >
        </el-form-item>
      </div>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button v-if="!disabled" type="primary" @click="dataFormSubmit()"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import { keys, pick } from 'lodash';

export default {
  data() {
    return {
      disabled: false,
      visible: false,
      dataForm: {
        id: '',
        name: '',
        isShow: true,
        orgId: '',
      },
      dataRule: {
        name: [{ required: true, message: '名称不能为空', trigger: 'blur' }],
      },
    };
  },
  created() {},
  methods: {
    init(id, disabled, orgId) {
      this.disabled = disabled;
      this.dataForm.id = id || '';
      if (this.dataForm.id === '') {
        this.dataForm.orgId = orgId;
      }
      this.visible = true;
      this.$nextTick(() => {
        this.$refs['dataForm'].resetFields();
        if (this.dataForm.id) {
          this.$http({
            url: `/cc/category/id/query/${this.dataForm.id}`,
            method: 'get',
          }).then(({ data }) => {
            if (data && data.status === 0) {
              this.dataForm = pick(data.data, keys(this.dataForm));
            }
          });
        }
      });
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          this.$http({
            url: `/cc/category/${!this.dataForm.id ? 'save' : 'id/update'}`,
            method: 'post',
            data: this.dataForm,
          }).then(({ data }) => {
            if (data && data.status === 0) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
              });
              this.visible = false;
              this.$emit('success');
            }
          });
        }
      });
    },
  },
};
</script>
