<template>
  <el-drawer
    :title="!dataForm.id ? '新增商品' : !disabled ? '修改商品' : '查看商品'"
    :visible.sync="visible"
    direction="rtl"
    append-to-body
    destroy-on-close
    :before-close="beforeClose"
    :close-on-press-escape="false"
    :wrapperClosable="false"
    class="normalDrwer"
  >
    <el-form
      :model="dataForm"
      :rules="dataRule"
      ref="dataForm"
      @keyup.enter.native="dataFormSubmit()"
      label-width="80px"
    >
      <div class="tablePart">
        <el-form-item label="商品名称" prop="name">
          <el-input
            v-model="dataForm.name"
            :disabled="disabled"
            placeholder="输入商品名称"
          />
        </el-form-item>
        <el-form-item label="商品简称" prop="briefName">
          <el-input
            v-model="dataForm.briefName"
            :disabled="disabled"
            placeholder="输入商品简称"
          />
        </el-form-item>
        <el-form-item label="商品编号" prop="sn">
          <el-input
            v-model="dataForm.sn"
            :disabled="disabled"
            placeholder="输入商品名称"
          />
        </el-form-item>
        <el-form-item label="商品分类" prop="categoryId">
          <el-select
            v-model="dataForm.categoryId"
            placeholder="商品分类"
            :disabled="disabled"
            filterable
            clearable
          >
            <el-option
              v-for="(item, index) in categoryList"
              :key="index"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="是否使用" prop="isUse">
          <el-tooltip
            class="item"
            effect="dark"
            style="line-height: 27px"
            placement="top"
          >
            <i class="el-icon-question" />
            <div style="width: 200px" slot="content">
              禁用后将不能购买该商品（现有订单不受影响）
            </div>
          </el-tooltip>
          <el-checkbox
            v-model="dataForm.isUse"
            :disabled="disabled"
            placeholder="是否使用"
            >是否使用</el-checkbox
          >
        </el-form-item>
        <el-form-item label="价格" prop="price">
          <el-input
            v-model="dataForm.price"
            :disabled="disabled"
            placeholder="输入价格"
          />
        </el-form-item>
        <el-form-item label="限购数量" prop="limitedNum">
          <el-input
            v-model="dataForm.limitedNum"
            :disabled="disabled"
            placeholder="输入限购数量"
          />
        </el-form-item>
        <el-form-item label="可现售" prop="allowInSale">
          <el-tooltip class="item" effect="dark" placement="top">
            <i class="el-icon-question" />
            <div style="width: 200px" slot="content">
              禁用后将不能购买该商品（现有订单不受影响）
            </div>
          </el-tooltip>
          <el-checkbox
            v-model="dataForm.allowInSales"
            :disabled="disabled"
            placeholder="可现售"
            >可现售</el-checkbox
          >
        </el-form-item>
        <el-form-item label="可进柜" prop="isInFreezer">
          <el-tooltip class="item" effect="dark" placement="top">
            <i class="el-icon-question" />
            <div style="width: 200px" slot="content">
              禁用后将该商品不可进柜
            </div>
          </el-tooltip>
          <el-checkbox
            v-model="dataForm.isInFreezer"
            :disabled="disabled"
            placeholder="可进柜"
            >可进柜</el-checkbox
          >
        </el-form-item>
        <el-form-item label="可堂食" prop="isCanteen">
          <el-tooltip class="item" effect="dark" placement="top">
            <i class="el-icon-question" />
            <div style="width: 200px" slot="content">
              禁用后将该商品不可堂食
            </div>
          </el-tooltip>
          <el-checkbox
            v-model="dataForm.isCanteen"
            :disabled="disabled"
            placeholder="可堂食"
            >可堂食</el-checkbox
          >
        </el-form-item>
        <el-form-item
          label="中转架规格"
          prop="shelfStyleIds"
          v-if="dataForm.isInFreezer"
        >
          <el-select
            v-model="dataForm.shelfStyleIds"
            filterable
            :disabled="disabled"
            multiple
          >
            <el-option
              v-for="(item, index) in shelfStyleList"
              :key="index"
              :label="item.shelfStyle"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="商品描述" prop="descriptionList" class="fullList">
          <el-button
            type="primary"
            size="mini"
            style="margin: 0 0 10px"
            @click="addDescription"
            class="el-icon-add-location"
            v-if="!disabled"
            >添加</el-button
          >
          <div class="form-table">
            <div class="table-header">
              <div class="table-header-item">属性</div>
              <div class="table-header-item">值</div>
              <div class="table-header-item" v-if="!disabled">操作</div>
            </div>
            <div class="table-body">
              <div
                v-for="(descriptions, index) in dataForm.descriptionList"
                :key="index"
                class="table-body-wrap"
              >
                <div class="table-body-item">
                  <el-input v-model="descriptions.key" :disabled="disabled" />
                </div>
                <div class="table-body-item">
                  <el-input v-model="descriptions.value" :disabled="disabled" />
                </div>
                <div class="table-body-item" v-if="!disabled">
                  <i
                    @click="handleDeleteItem(descriptions, index)"
                    class="el-icon-delete"
                  />
                </div>
              </div>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="商品图片" prop="imageUrl" class="fullList">
          <image-cropper-modal
            :visible="cropperVisible"
            :url="file"
            :auto-crop-width="autoCropWidth"
            :auto-crop-height="autoCropHeight"
            @cancel="cropperVisible = false"
            @confirm="onConfirm"
          />
          <el-upload
            v-model="dataForm.imageUrl"
            class="avatar-uploader"
            action="#"
            :auto-upload="false"
            :show-file-list="false"
            :on-change="changeUpload"
          >
            <img
              v-if="dataForm.imageUrl"
              :src="dataForm.imageUrl"
              class="avatar"
              alt=""
            />
            <i v-else class="el-icon-plus avatar-uploader-icon" />
          </el-upload>
        </el-form-item>

        <el-form-item label="商品图片链接" prop="imageUrl" class="fullList">
          <el-input v-model="dataForm.imageUrl"></el-input>
        </el-form-item>

        <!--                <el-form-item label="销量" prop="salesVolume">-->
        <!--                    <el-input-->
        <!--                            v-model="dataForm.salesVolume"-->
        <!--                            :disabled="disabled"-->
        <!--                            placeholder="输入销量"-->
        <!--                    ></el-input>-->
        <!--                </el-form-item>-->
      </div>
    </el-form>
    <div class="btnGroup">
      <el-button @click="btnClose">取消</el-button>
      <el-button v-if="!disabled" type="primary" @click="dataFormSubmit()"
        >确定</el-button
      >
    </div>
  </el-drawer>
</template>

<script>
import { keys, pick } from 'lodash';

export default {
  data() {
    return {
      disabled: false,
      visible: false,
      dataForm: {
        id: '',
        name: '',
        briefName: '',
        sn: '',
        categoryId: '',
        descriptionList: [],
        imageUrl: '',
        isUse: true,
        price: '',
        limitedNum: '',
        // salesVolume: '',
        allowInSales: true,
        isInFreezer: true,
        isCanteen: true,
        orgId: '',
        shelfStyleId: '',
        shelfStyleIds: [],
      },
      autoCropWidth: '400',
      autoCropHeight: '400',
      file: '',
      fileName: '',
      cropperVisible: false,
      url: '',
      shelfStyleList: [],
      categoryList: [],
      dataRule: {
        name: [
          { required: true, message: '商品名称不能为空', trigger: 'blur' },
        ],
        briefName: [
          { required: true, message: '商品简称不能为空', trigger: 'blur' },
        ],
        sn: [{ required: true, message: '商品编号不能为空', trigger: 'blur' }],
        categoryId: [
          { required: true, message: '商品分类不能为空', trigger: 'blur' },
        ],
        price: [
          { required: true, message: '商品价格不能为空', trigger: 'blur' },
        ],
        shelfStyleIds: [
          { required: true, message: '中转架规格不能为空', trigger: 'blur' },
        ],
      },
    };
  },
  components: {},
  created() {
    this.getShelfStyle();
  },
  methods: {
    init(id, disabled, orgId) {
      this.disabled = disabled;
      this.dataForm.id = id || '';
      this.dataForm.orgId = orgId;
      this.visible = true;
      this.file = '';
      this.fileName = '';
      this.$nextTick(() => {
        this.$refs['dataForm'].resetFields();
        if (this.dataForm.id) {
          this.$http({
            url: `/tc/goods/info/${this.dataForm.id}`,
            method: 'get',
          }).then(({ data }) => {
            if (data && data.code === 0) {
              this.dataForm = pick(data.goods, keys(this.dataForm));
            }
          });
        }
      });
      this.getCategoryList();
    },
    changeUpload(file) {
      var img = file.name.substring(file.name.lastIndexOf('.') + 1);
      const suffix = img === 'jpg' || img === 'png' || img === 'jpeg';
      if (!suffix) {
        this.$message.error('只能上传图片');
        return false;
      }
      const reader = new FileReader();
      reader.onload = () => {
        this.file =
          typeof reader.result === 'object'
            ? window.URL.createObjectURL(new Blob([reader.result]))
            : reader.result;
      };
      this.cropperVisible = true;
      reader.readAsArrayBuffer(file.raw);
      this.fileName = file.name;
    },
    async onConfirm(blob) {
      const form = new FormData();
      form.append('file', blob);
      this.$http({
        url: `/cc/oss/image/upload`,
        method: 'post',
        data: form,
      }).then(({ data }) => {
        if (data && data.status === 0) {
          this.dataForm.imageUrl = data.data.url;
          this.cropperVisible = false;
        }
      });
    },
    uploadImg(data) {
      this.$http({
        url: `/cc/oss/image/upload`,
        method: 'post',
        data,
      }).then(({ data }) => {
        if (data && data.code === 0) {
          this.dataForm = data.goods;
        }
      });
    },
    beforeClose(done) {
      this.$nextTick(() => {
        this.$refs['dataForm'].resetFields();
      });
      done();
    },
    btnClose() {
      this.$nextTick(() => {
        this.$refs['dataForm'].resetFields();
      });
      this.visible = false;
    },
    getCategoryList() {
      this.$http({
        url: `/cc/category/all`,
        params: {
          orgId: this.dataForm.orgId,
        },
      }).then(({ data }) => {
        if (data && data.status === 0) {
          this.categoryList = data.data.items;
        }
      });
    },
    getShelfStyle() {
      this.$http({
        url: '/cc/shelfstyle/shelfStyle',
        method: 'get',
      }).then(({ data }) => {
        if (data && data.code === 0) {
          this.shelfStyleList = data.shelfStyleList;
        } else {
          this.shelfStyleList = [];
        }
      });
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          this.$http({
            url: `/tc/goods/${!this.dataForm.id ? 'save' : 'update'}`,
            method: 'post',
            data: {
              ...this.dataForm,
            },
          }).then(({ data }) => {
            if (data && data.code === 0) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
              });
              this.visible = false;
              this.$refs['dataForm'].resetFields();
              this.$emit('success');
            }
          });
        }
      });
    },
    handleDeleteItem(item, index) {
      this.dataForm.descriptionList.splice(index, 1);
      // this.dataForm.descriptionList = this.dataForm.descriptionList.filter(
      //   (i) => i.timeId !== item.timeId
      // )
    },
    addDescription() {
      this.dataForm.descriptionList.push({
        key: '',
        value: '',
      });
    },
  },
};
</script>

<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  border-radius: 0 !important;
  display: block;
}
</style>
